
    @import "config.scss";
 
.loginLogos {
  display: flex;
  align-items: center;
  gap: 2vw;

  @include for-desktop-up {
    // width: 20rem;
  }
}

.cart {
  position: relative;
  margin-left: 8px;
}

.itemCount {
  background-color: white;
  border-radius: 50px;
  height: 28%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 1px;
}
